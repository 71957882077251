import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { DownloadIcon } from "../../images/Icons";
import Breadcrumb from "../../components/breadcrumb";

import Business1 from "../../images/press/featured-businesses/business-1.jpg"
import Business2 from "../../images/press/featured-businesses/business-2.jpg"
import Business3 from "../../images/press/featured-businesses/business-3.jpg"
import Business4 from "../../images/press/featured-businesses/business-4.jpg"
import Business5 from "../../images/press/featured-businesses/business-5.jpg"
import Business6 from "../../images/press/featured-businesses/business-6.jpg"
import Business7 from "../../images/press/featured-businesses/business-7.jpg"
import Business8 from "../../images/press/featured-businesses/business-8.jpg"

const FeaturedBusinessesPage = () => {
  const { t } = useTranslation();
  return (
    <Layout pageTitle="Featured Businesses - Moniepoint Inc.">
      <ProductImagesContainer>
        <div className="content">
          <Breadcrumb
            title="Press"
            link="/press"
            current="presspage.featured_businesses"
          />
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.featured_businesses</Trans>
            </h2>
          </div>

          <div className="image-section">
            <div className="section-header">
              <h3 className="title">
                <Trans>presspage.category_1</Trans>
              </h3>
              <a download="Featured-Business-1.zip" href="https://drive.google.com/uc?export=download&id=1AmZwETdJ8Fa21_SvLvrlTYnVZx1gVudk" className="btn download-btn">
                <Trans>download_all</Trans>
                <DownloadIcon />
              </a>
            </div>
            <div className="section-body">
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-1.jpg"
                  className="product-image grow"
                />
                <a download={`${t("presspage.category_1")}-1.jpg`} href={Business1} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-2.jpg"
                  className="product-image grow"
                />
                <a download="business-2.jpg" href={Business2} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-3.jpg"
                  className="product-image grow"
                />
                <a download="business-3.jpg" href={Business3} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-4.jpg"
                  className="product-image grow"
                />
                <a download="business-4.jpg" href={Business4} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
            </div>
          </div>
          <div className="image-section">
            <div className="section-header">
              <h3 className="title">
                <Trans>presspage.category_2</Trans>
              </h3>
              <a download="Featured-Business-1.zip" href="https://drive.google.com/uc?export=download&id=10RoQioyf3Dt2-SX8eJOGZ8GyStAWE88A" className="btn download-btn">
                <Trans>download_all</Trans>
                <DownloadIcon />
              </a>
            </div>
            <div className="section-body">
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-5.jpg"
                  className="product-image grow"
                />
                <a download="business-5.jpg" href={Business5} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-6.jpg"
                  className="product-image grow"
                />
                <a download="business-6.jpg" href={Business6} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-7.jpg"
                  className="product-image grow"
                />
                <a download="business-7.jpg" href={Business7} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
              <div className="image-container">
                <StaticImage
                  alt="..."
                  src="../../images/press/featured-businesses/business-8.jpg"
                  className="product-image grow"
                />
                <a download="business-8.jpg" href={Business8} className="btn download-link">
                  <DownloadIcon size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </ProductImagesContainer>
    </Layout>
  );
};

export default FeaturedBusinessesPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ProductImagesContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 0 150px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 0 60px 150px;
    }

    .top-div {
      margin-bottom: 16px;

      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 57px;
        max-width: 851px;
      }
    }
    .bottom-div {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .text-content {
        font-size: 20px;
        font-weight: 400;
        line-height: 32.2px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 586px;
        margin-bottom: 0;
      }
    }

    .image-section {
      margin-top: 48px;
      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 48px;

        .title {
          font-family: "Founders Grotesk";
          font-size: 36px;
          font-weight: 500;
          line-height: 42px;
          letter-spacing: 0em;
          text-align: left;
        }

        .button {
          white-space: nowrap;
          background-color: rgba(3, 87, 238, 0.1);
          color: rgba(3, 97, 240, 1);
          padding: 16px 24px;
          border-radius: 4px;
          gap: 4px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: fit-content;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 130%;
          text-align: center;
          text-decoration: none;

          svg {
            path {
              fill: rgba(3, 97, 240, 1);
            }
          }

          :hover {
            background-color: rgba(3, 87, 238, 0.2);
          }
        }
      }
      .section-body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 49px;
        padding-bottom: 20px;

        .image-container {
          max-width: 248px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .product-image {
            width: 100%;
            height: 235px;
            object-fit: cover;
            border-radius: 8px;
            margin-bottom: 12px;
          }

          .btn.download-link {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 0 20px 64px;

      .top-div {
        margin-bottom: 16px;
        .title {
          font-size: 32px;
          line-height: 37.76px;
        }
      }
      .bottom-div {
        flex-direction: column;
        align-items: flex-start;
        .text-content {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 16px;
        }
      }

      .image-section {
        .section-header {
          margin-bottom: 32px;
          .title {
            font-size: 24px;
            line-height: 28px;
          }
        }
        .section-body {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
        }
      }
    }
  }
`;
